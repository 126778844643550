import React, { useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router-dom"

import { useBookContext } from "../../../../contexts/Mobile/BookContext"
import { useCalculateBookable } from "../../../../hooks/useCalculateBookable"
import { useToast } from "../../../../hooks/useToast"
import { useAnalyticsScreenView } from "../../../../providers/Mobile/FirebaseAnalyticsProvider"
import {
  isRecurringType,
  RecurringType,
  RepeatPickerTypes,
} from "../../../../types/sharedTypes"
import { internalTimeFormat } from "../../../../utils"
import { DESK_PATHS } from "./constants"
import NoDeskFound from "./NoDeskFound"

import { useCreateDeskReservationMutation } from "../../../../redux/api/deskReservations"
import { useFetchDeskQuery } from "../../../../redux/api/desks"
import { useAppSelector } from "../../../../redux/reducers"
import { TimeslotResponse } from "../../../../redux/timeslots/types"
import { selectUser } from "../../../../redux/user/selectors"

import Loader from "../../../../components/basic/Loader"
import { CorrectIconMapper } from "../../../../components/CorrectIconMapper"
import { DateTimePicker } from "../../../../components/Mobile/DateTimePicker"
import SafeViewArea from "../../../../components/Mobile/SafeViewArea"
import { TopNav } from "../../../../components/Mobile/TopNav"

import "./AdhocDeskConfirm.sass"

const AdhocDeskConfirm = () => {
  useAnalyticsScreenView("Book/Desks/AdhocDeskConfirm")

  const { infoToast, errorToast } = useToast()
  const { t } = useTranslation()
  const {
    date,
    timeslot,
    repeat,
    repeatUntil,
    onDateTimePick,
    onAdhocBooking,
    goToHome,
  } = useBookContext()
  const history = useHistory()
  const [createDeskReservation] = useCreateDeskReservationMutation()
  const location = useLocation<{ desk_id: string }>()
  const deskId = location.state?.desk_id
  const [selectedDate, setSelectedDate] = useState(date)
  const [selectedTimeslot, setSelectedTimeslot] = useState(
    timeslot || undefined,
  )
  const startTime = selectedTimeslot?.from
    ? dayjs(`${date.format("YYYY-MM-DD")}T${selectedTimeslot.from}`)
    : null
  const endTime = selectedTimeslot?.to
    ? dayjs(`${date.format("YYYY-MM-DD")}T${selectedTimeslot.to}`)
    : null
  const [selectedRepeat, setSelectedRepeat] = useState(repeat)
  const [selectedRepeatUntil, setSelectedRepeatUntil] = useState(repeatUntil)
  const user = useAppSelector(selectUser)

  // Fetch desk data
  const { data: desk, isLoading: isDeskLoading } = useFetchDeskQuery(deskId, {
    skip: !deskId,
  })

  // Fetch bookable desks
  const { desksBookable, isLoading: isBookableLoading } = useCalculateBookable({
    date: selectedDate.toISOString(),
    buildingId: desk?.building_id,
    floorId: desk?.floor_id,
    timeslot: selectedTimeslot,
  })

  // Show desk status
  const deskIsAlreadyReserved = desksBookable[deskId] === false
  const deskStatus = deskIsAlreadyReserved
    ? t("mobile.home.reserved")
    : t("desktop.manage.desk_booking.available")

  const handleClose = () => goToHome()

  const handleDateTimePick = (
    newDate: Dayjs | null,
    newTimeslot: Partial<TimeslotResponse> | null,
    newRepeat: RepeatPickerTypes | null,
    newRepeatUntil: Dayjs | null,
  ) => {
    // Update the date, timeslot, repeat, and repeatUntil in the context
    onDateTimePick(newDate, newTimeslot, newRepeat, newRepeatUntil)

    // Update local state
    if (newDate) {
      setSelectedDate(newDate)
    }
    setSelectedTimeslot(newTimeslot || undefined)
    setSelectedRepeat(newRepeat)
    setSelectedRepeatUntil(newRepeatUntil)
  }

  const handleBookNow = async () => {
    // Set the desk in context
    onAdhocBooking(desk ?? null)

    // Create desk reservation
    try {
      if (!desk) {
        return
      }

      await createDeskReservation({
        tz: desk.tz,
        desk_id: deskId,
        user_email: user.entry.email,
        timeslot_id: selectedTimeslot?.id ?? "",
        start: startTime?.toISOString(),
        end: endTime?.toISOString(),
        ...(selectedRepeat &&
        isRecurringType(selectedRepeat) &&
        selectedRepeatUntil
          ? {
              recurring: {
                freq: selectedRepeat as RecurringType,
                until: selectedRepeatUntil.toISOString(),
              },
            }
          : {}),
      }).unwrap()

      infoToast(t("mobile.desk_booking.success"))

      history.push(DESK_PATHS.home)
    } catch (error) {
      errorToast(t("mobile.desk_booking.error"))
    }
  }

  const isLoading = isDeskLoading || isBookableLoading

  return (
    <SafeViewArea className="AdhocDeskConfirm">
      <div className="head">
        <TopNav onClose={handleClose} />
        <div className="icon">
          <div>
            <CorrectIconMapper iconType="desk" needsWrap={false} />
          </div>
        </div>
      </div>

      <div className="body">
        {isLoading && <Loader variant="fullScreen" />}

        {deskId && desk && !isLoading && (
          <>
            <div className="main-data">
              <div className="info">
                <div className="type">{t("mobile.general.desk")}</div>
                <div className="status">{deskStatus}</div>
              </div>
              <div className="name">{desk.name}</div>
              <div className="location">
                {desk.floor.name}, {desk.building.name}
              </div>
            </div>

            <DateTimePicker
              date={selectedDate}
              useTodayAsDefaultDate
              timeslot={selectedTimeslot || null}
              repeat={selectedRepeat || undefined}
              repeatUntil={selectedRepeatUntil}
              preferredTime={
                selectedDate.isToday()
                  ? dayjs().format(internalTimeFormat())
                  : dayjs("9:00", internalTimeFormat()).format(
                      internalTimeFormat(),
                    )
              }
              onPick={handleDateTimePick}
              onAdhocBooking={handleBookNow}
              onlyInput
            />
          </>
        )}

        {(!deskId || !desk) && !isLoading && (
          <div className="main-data">
            <NoDeskFound />
          </div>
        )}
      </div>
    </SafeViewArea>
  )
}

export default AdhocDeskConfirm
